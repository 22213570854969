<template>
    <div class="mv">
		<div class="mv-bg"></div>
		<div class="mv_cloud">
			<div class="right_area">
				<div class="img04 present"><img src="@/assets/front_component/images/assets/mv04.png" alt="雲"></div>
				<div class="img05 present"><img src="@/assets/front_component/images/assets/mv05.png" alt="雲"></div>
			</div>
			<div class="left_area">
				<div class="img01 present"><img src="@/assets/front_component/images/assets/mv01.png" alt="雲"></div>
				<div class="img02 present"><img src="@/assets/front_component/images/assets/mv02.png" alt="雲"></div>
			</div>
		</div>
		<div class="mv_city">
			<div class="mv_city--item present">
				<div class="level05">
					<div class="level05--img01"><img src="@/assets/front_component/images/assets/sdgs/city16.png" alt="気球"></div>
					<div class="level05--img02"><img src="@/assets/front_component/images/assets/sdgs/city17.png" alt="気球"></div>
				</div>
				<div class="level04">
					<div class="level04--img01"><img src="@/assets/front_component/images/assets/sdgs/city05.png" alt="風船"></div>
					<div class="level04--img02"><img src="@/assets/front_component/images/assets/sdgs/city06.png" alt="風船"></div>
					<div class="level04--img03"><img src="@/assets/front_component/images/assets/sdgs/city07.png" alt="風船"></div>
					<div class="level04--img04"><img src="@/assets/front_component/images/assets/sdgs/city08.png" alt="鳥"></div>
					<div class="level04--img05"><img src="@/assets/front_component/images/assets/sdgs/city09.png" alt="鳥"></div>
				</div>
				<div class="img01"><img src="@/assets/front_component/images/assets/sdgs/city01.png" alt="街並み"></div>
				<div class="img02">
					<img src="@/assets/front_component/images/assets/sdgs/city02.png" alt="太陽">
					<div class="shine level02">
						<div class="border border--01"></div>
						<div class="border border--02"></div>
						<div class="border border--03"></div>
						<div class="border border--04"></div>
						<div class="border border--05"></div>
						<div class="border border--06"></div>
						<div class="border border--07"></div>
					</div>
				</div>
				<div class="level02">
					<div class="level02--img01"><img src="@/assets/front_component/images/assets/sdgs/city03.png" alt="パネル"></div>
				</div>
				<div class="level03">
					<div class="level03--img01">
						<img src="@/assets/front_component/images/assets/sdgs/city04.png" alt="街並み">
						<div class="star star01"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star02"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star03"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star04"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star05"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star06"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star07"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star08"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star09"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star10"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star11"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star12"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star13"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star14"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
					</div>
				</div>
			</div>
			<div class="mv_city--item future">
				<div class="level12">
					<div class="level12--img01">
						<img src="@/assets/front_component/images/assets/sdgs/city27.png" alt="塔">
						<div class="star star01"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star02"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star03"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star04"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star05"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star06"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star07"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
					</div>
				</div>
				<div class="level11">
					<div class="level11--img01"><img src="@/assets/front_component/images/assets/sdgs/city25.png" alt="鳥"></div>
					<div class="level11--img02"><img src="@/assets/front_component/images/assets/sdgs/city26.png" alt="鳥"></div>
				</div>
				<div class="level15">
					<div class="level15--img01"><img src="@/assets/front_component/images/assets/sdgs/city28.png" alt="花火"></div>
				</div>
				<div class="img04">
					<img src="@/assets/front_component/images/assets/sdgs/city22.png" alt="月">
					<div class="shine level10">
						<div class="border border--01"></div>
						<div class="border border--02"></div>
						<div class="border border--03"></div>
						<div class="border border--04"></div>
						<div class="border border--05"></div>
						<div class="border border--06"></div>
						<div class="border border--07"></div>
					</div>
				</div>
				<div class="img02"><img src="@/assets/front_component/images/assets/sdgs/city19.png" alt="星"></div>
				<div class="img03">
					<div class="img03--01"><img src="@/assets/front_component/images/assets/sdgs/city20.png" alt="光"></div>
					<div class="img03--02"><img src="@/assets/front_component/images/assets/sdgs/city21.png" alt="光"></div>
					<div class="img03--03"><img src="@/assets/front_component/images/assets/sdgs/city21.png" alt="光"></div>
					<div class="img03--04"><img src="@/assets/front_component/images/assets/sdgs/city21.png" alt="光"></div>
					<div class="img03--05"><img src="@/assets/front_component/images/assets/sdgs/city21.png" alt="光"></div>
				</div>
				<div class="img01"><img src="@/assets/front_component/images/assets/sdgs/city18.png" alt="街並み"></div>
			</div>
			<div class="mv_city--layer01 present">
				<div class="img01"><img src="@/assets/front_component/images/assets/sdgs/layer01/img01.png" alt="木"></div>
				<div class="level04">
					<div class="level04--img01">
						<img src="@/assets/front_component/images/assets/sdgs/city10.png" alt="風車">
						<div class="hane"><img src="@/assets/front_component/images/assets/sdgs/city11.png" alt="風車"></div>
					</div>
					<div class="level04--img02">
						<img src="@/assets/front_component/images/assets/sdgs/city12.png" alt="風車">
						<div class="hane"><img src="@/assets/front_component/images/assets/sdgs/city13.png" alt="風車"></div>
						<div class="star star01"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star02"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star03"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star04"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star05"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
					</div>
					<div class="level04--img03">
						<img src="@/assets/front_component/images/assets/sdgs/city14.png" alt="風車">
						<div class="hane"><img src="@/assets/front_component/images/assets/sdgs/city15.png" alt="風車"></div>
					</div>
				</div>
				<div class="level02">
					<div class="level02--img04"><img src="@/assets/front_component/images/assets/sdgs/layer01/img05.png" alt="木"></div>
					<div class="level02--img01">
						<img src="@/assets/front_component/images/assets/sdgs/layer01/img02.png" alt="パネル">
						<div class="star star01"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star02"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star03"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
					</div>
					<div class="level02--img02">
						<img src="@/assets/front_component/images/assets/sdgs/layer01/img03.png" alt="パネル">
						<div class="star star01"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star02"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star03"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
					</div>
					<div class="level02--img03">
						<img src="@/assets/front_component/images/assets/sdgs/layer01/img04.png" alt="パネル">
						<div class="star star01"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star02"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star03"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
					</div>
				</div>
				<div class="level05">
					<div class="level05--img01"><img src="@/assets/front_component/images/assets/sdgs/layer01/img07.png" alt="花"></div>
					<div class="level05--img02"><img src="@/assets/front_component/images/assets/sdgs/layer01/img08.png" alt="花"></div>
				</div>
			</div>
			<div class="mv_city--layer01 future">
				<div class="img01"><img src="@/assets/front_component/images/assets/sdgs/layer01/img09.png" alt="木"></div>
				<div class="level10">
					<div class="level10--img01">
						<img src="@/assets/front_component/images/assets/sdgs/city23.png" alt="オブジェ">
						<div class="star star01"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star02"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star03"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star04"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star05"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star06"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
					</div>
					<div class="level10--img02">
						<img src="@/assets/front_component/images/assets/sdgs/city24.png" alt="オブジェ">
						<div class="star star01"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star02"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star03"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star04"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star05"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star06"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="shine level10">
							<div class="border border--01"></div>
							<div class="border border--02"></div>
							<div class="border border--03"></div>
							<div class="border border--04"></div>
							<div class="border border--05"></div>
							<div class="border border--06"></div>
							<div class="border border--07"></div>
						</div>
					</div>
				</div>
				<div class="level11">
					<div class="level11--img01">
						<img src="@/assets/front_component/images/assets/sdgs/layer01/img10.png" alt="木々">
						<div class="star star01"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star02"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star03"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star04"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star05"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star06"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star07"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star08"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star09"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star10"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star11"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star12"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star13"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star14"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star15"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star16"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star17"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
						<div class="star star18"><img src="@/assets/front_component/images/assets/sdgs/layer01/star03.png" alt="星"></div>
						<div class="star star19"><img src="@/assets/front_component/images/assets/sdgs/layer01/star01.png" alt="星"></div>
						<div class="star star20"><img src="@/assets/front_component/images/assets/sdgs/layer01/star02.png" alt="星"></div>
					</div>
				</div>
				<div class="level12">
					<div class="level12--img01">
						<img src="@/assets/front_component/images/assets/sdgs/layer01/img11.png" alt="花">
					</div>
				</div>
				<div class="level15">
					<div class="level15--img01"><img src="@/assets/front_component/images/assets/sdgs/layer01/img12.png" alt="花"></div>
				</div>
			</div>
			<div class="mv_city--layer02 present">
				<div class="img01"><img src="@/assets/front_component/images/assets/sdgs/layer02/img01.png" alt="人"></div>
				<div class="img02"><img src="@/assets/front_component/images/assets/sdgs/layer02/img02.png" alt="人"></div>
				<div class="img03"><img src="@/assets/front_component/images/assets/sdgs/layer02/img03.png" alt="人"></div>
				<div class="img04"><img src="@/assets/front_component/images/assets/sdgs/layer02/img04.png" alt="人"></div>
				<div class="img05"><img src="@/assets/front_component/images/assets/sdgs/layer02/img05.png" alt="人"></div>
				<div class="img06"><img src="@/assets/front_component/images/assets/sdgs/layer02/img06.png" alt="人"></div>
				<div class="img07"><img src="@/assets/front_component/images/assets/sdgs/layer02/img07.png" alt="人"></div>
				<div class="img08"><img src="@/assets/front_component/images/assets/sdgs/layer02/img08.png" alt="人"></div>
				<div class="img09"><img src="@/assets/front_component/images/assets/sdgs/layer02/img09.png" alt="人"></div>
				<div class="level05">
					<div class="level05--img01"><img src="@/assets/front_component/images/assets/sdgs/layer02/img10.png" alt="人"></div>
					<div class="level05--img02"><img src="@/assets/front_component/images/assets/sdgs/layer02/img11.png" alt="人"></div>
					<div class="level05--img03"><img src="@/assets/front_component/images/assets/sdgs/layer02/img12.png" alt="人"></div>
					<div class="level05--img04"><img src="@/assets/front_component/images/assets/sdgs/layer02/img13.png" alt="人"></div>
					<div class="level05--img05"><img src="@/assets/front_component/images/assets/sdgs/layer02/img14.png" alt="人"></div>
					<div class="level05--img06"><img src="@/assets/front_component/images/assets/sdgs/layer02/img15.png" alt="人"></div>
					<div class="level05--img07"><img src="@/assets/front_component/images/assets/sdgs/layer02/img16.png" alt="人"></div>
					<div class="level05--img08"><img src="@/assets/front_component/images/assets/sdgs/layer02/img17.png" alt="人"></div>
					<div class="level05--img09"><img src="@/assets/front_component/images/assets/sdgs/layer02/img18.png" alt="人"></div>
				</div>
			</div>
			<div class="mv_city--layer02 future">
				<div class="img01"><img src="@/assets/front_component/images/assets/sdgs/layer02/img19.png" alt="人"></div>
				<div class="img02"><img src="@/assets/front_component/images/assets/sdgs/layer02/img20.png" alt="人"></div>
				<div class="img03"><img src="@/assets/front_component/images/assets/sdgs/layer02/img21.png" alt="人"></div>
				<div class="img04"><img src="@/assets/front_component/images/assets/sdgs/layer02/img22.png" alt="人"></div>
				<div class="img05"><img src="@/assets/front_component/images/assets/sdgs/layer02/img23.png" alt="人"></div>
				<div class="img06"><img src="@/assets/front_component/images/assets/sdgs/layer02/img24.png" alt="人"></div>
				<div class="img07"><img src="@/assets/front_component/images/assets/sdgs/layer02/img25.png" alt="人"></div>
				<div class="img08"><img src="@/assets/front_component/images/assets/sdgs/layer02/img26.png" alt="人"></div>
				<div class="img09"><img src="@/assets/front_component/images/assets/sdgs/layer02/img27.png" alt="人"></div>
				<div class="level15">
					<div class="level15--img01"><img src="@/assets/front_component/images/assets/sdgs/layer02/img29.png" alt="人"></div>
					<div class="level15--img02"><img src="@/assets/front_component/images/assets/sdgs/layer02/img30.png" alt="人"></div>
					<div class="level15--img03"><img src="@/assets/front_component/images/assets/sdgs/layer02/img31.png" alt="人"></div>
					<div class="level15--img04"><img src="@/assets/front_component/images/assets/sdgs/layer02/img32.png" alt="人"></div>
					<div class="level15--img05"><img src="@/assets/front_component/images/assets/sdgs/layer02/img33.png" alt="人"></div>
					<div class="level15--img06"><img src="@/assets/front_component/images/assets/sdgs/layer02/img34.png" alt="人"></div>
					<div class="level15--img07"><img src="@/assets/front_component/images/assets/sdgs/layer02/img35.png" alt="人"></div>
					<div class="level15--img08"><img src="@/assets/front_component/images/assets/sdgs/layer02/img36.png" alt="人"></div>
					<div class="level15--img09"><img src="@/assets/front_component/images/assets/sdgs/layer02/img37.png" alt="人"></div>
				</div>
			</div>
		</div>
		<div class="mv_content">
			<div class="left_area">
				<div class="meguru">
					<div class="meguru--img">
						<div class="meguru--img--light tyoki100"><img src="@/assets/front_component/images/assets/sdgs/content/meguru03.png" alt="光"></div>
						<img src="@/assets/front_component/images/assets/sdgs/content/meguru01.png" alt="めぐる君">
						<div class="meguru--img--heart">
							<div class="meguru--img--heart--item tankilevel10">
								<img src="@/assets/front_component/images/assets/sdgs/content/heart10.png" alt="ハート">
							</div>
							<div class="meguru--img--heart--item tankilevel09">
								<img src="@/assets/front_component/images/assets/sdgs/content/heart09.png" alt="ハート">
							</div>
							<div class="meguru--img--heart--item tankilevel08">
								<img src="@/assets/front_component/images/assets/sdgs/content/heart08.png" alt="ハート">
							</div>
							<div class="meguru--img--heart--item tankilevel07">
								<img src="@/assets/front_component/images/assets/sdgs/content/heart07.png" alt="ハート">
							</div>
							<div class="meguru--img--heart--item tankilevel06">
								<img src="@/assets/front_component/images/assets/sdgs/content/heart06.png" alt="ハート">
							</div>
							<div class="meguru--img--heart--item tankilevel05">
								<img src="@/assets/front_component/images/assets/sdgs/content/heart05.png" alt="ハート">
							</div>
							<div class="meguru--img--heart--item tankilevel04">
								<img src="@/assets/front_component/images/assets/sdgs/content/heart04.png" alt="ハート">
							</div>
							<div class="meguru--img--heart--item tankilevel03">
								<img src="@/assets/front_component/images/assets/sdgs/content/heart03.png" alt="ハート">
							</div>
							<div class="meguru--img--heart--item tankilevel02">
								<img src="@/assets/front_component/images/assets/sdgs/content/heart02.png" alt="ハート">
							</div>
							<div class="meguru--img--heart--item tankilevel01">
								<img src="@/assets/front_component/images/assets/sdgs/content/heart01.png" alt="ハート">
							</div>
						</div>
						<div class="meguru--img--watermark"><img src="@/assets/front_component/images/assets/sdgs/content/meguru02.png" alt="透かし"></div>
					</div>
					<div class="meguru--per">
						<div class="meguru--per--box">
							<div class="meguru--per--content">
								<p class="meguru--per--ttl">短期目標達成率</p>
								<p class="meguru--per--val"><span class="num">0</span><span class="per">%</span></p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="right_area">
				<div class="info">
					<p class="info__ttl">SDGs <span class="num">{{ fiscalValue }}</span>期累計</p>
					<p class="info__count"><span class="num">{{ totalCount }}</span><span class="unit">件</span></p>
					<div class="info__bar">
						<div class="info__bar--head">
							<p class="info__bar--head--ttl">短期実績</p>
							<p class="info__bar--head--count">目標：<span class="num">{{ shortTermCount }}</span>件</p>
						</div>
						<div class="info__bar--progress">
							<div class="bar yellow"></div>
						</div>
					</div>
					<div class="info__bar">
						<div class="info__bar--head">
							<p class="info__bar--head--ttl">通常実績</p>
							<p class="info__bar--head--count">目標：<span class="num">{{ longTermCount }}</span>件</p>
						</div>
						<div class="info__bar--progress">
							<div class="bar orange"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="popup">
			<div class="popup_wrap">
				<div class="popup_inner">
				</div>
			</div>
		</div>
	</div>
	<div class="section_all_wrap">
		<div class="content_in_wrap">
			<div class="help arrow" id="help-button" @click="showPopup">
			<div class="img_wrap">
			<img src="@/assets/front_component/images/assets/sdgs/icon01.png" alt="戻る">
			</div>
		</div>
		</div>
	</div>
</template>

<script>
import $ from "jquery";
import SDGs from "@/apis/SDGs";
import constSdgs from '@/const/control_panel/sdgs'


export default {
    data(){
        return{
            totalCount: 0,
            longTermCount: 0,
            shortTermCount: 0,
            longTermRatio: 0,
            shortTermRatio: 0,
			fiscalValue: 50,
			popupContent: 0,
			isUserRecentLogin: false
        }
    },
    mounted(){
        //ポップアップを表示
		// $('#help-button').on('click', function(data) {
		// 	console.log('sample val',data);
		// 	$(".popup_text").text('Hello');
		// 	$('.popup').toggleClass('show'); // show クラスの追加/削除をトグル
		// 	$('.popup').addClass('help'); // help クラスの追加/削除をトグル
		// });

		this.isUserRecentLogin = localStorage.getItem("isUserRecentLogin");

		if (this.isUserRecentLogin == 'true') {
			this.showPopup()
		}
		// popup_wrap の外側をクリックしたときの処理
		$(document).on('click', function(event) {
			// クリックされた要素が popup_wrap の内部、popup 要素、または help-button でない場合
			if (!$(event.target).closest('.popup_wrap').length && 
				!$(event.target).closest('#help-button').length) {
				$('.popup').removeClass('show'); // show クラスを削除

				if (localStorage.getItem("isUserRecentLogin") == 'true') {
					localStorage.setItem("isUserRecentLogin", false)
				}

			}
		});
        SDGs.sdgsRatio().then((response)=>{
            let responseData = response.data;

			if (responseData) {
				this.totalCount = new Intl.NumberFormat().format(responseData.total_count);
				this.longTermCount = new Intl.NumberFormat().format(responseData.long_term_count) 
				this.shortTermCount = new Intl.NumberFormat().format(responseData.short_term_count)
				this.longTermRatio = responseData.long_term_ratio
				this.shortTermRatio = responseData.short_term_ratio
	
				let fiscalYear = constSdgs.fiscalYears.find((el)=>{
					return el.year == responseData.currentYear
				})
				this.fiscalValue = fiscalYear.fiscalValue;

				this.setProgress(this.shortTermRatio, this.longTermRatio);
			}
        }).catch((err)=>{
            console.log(err);
			this.$router.push({ name: 'Front Error' });
        })
		
    },
    methods:{
		showPopup(){
			let popup = document.querySelector('.popup');
			let shortTermRatio = this.shortTermRatio;
			let popupInner = document.getElementsByClassName("popup_inner");
			if(shortTermRatio <= 100) {
				this.popupContent = shortTermRatio;
			}
			else if(shortTermRatio <= 90){
				this.popupContent = shortTermRatio;
			}
			else if(shortTermRatio <= 80){
				this.popupContent = shortTermRatio;
			}
			else if(shortTermRatio <= 70){
				this.popupContent = shortTermRatio;
			}
			else if(shortTermRatio <= 60){
				this.popupContent = shortTermRatio;
			}
			else if(shortTermRatio <= 50){
				this.popupContent = shortTermRatio;
			}
			else if(shortTermRatio <= 40){
				this.popupContent = shortTermRatio;
			}
			else if(shortTermRatio <= 30){
				this.popupContent = shortTermRatio;
			}
			else if(shortTermRatio <= 20){
				this.popupContent = shortTermRatio;
			} else {
				this.popupContent = shortTermRatio;
			}
			popup.classList.toggle('show'); // show クラスの追加/削除をトグル
			popup.classList.add('help'); // help クラスの追加/削除をトグル
			// popupText[0].innerText = `未来ステージ突入！<br>あとひといきで100％<br>がんばりましょう！ ${this.popupContent}`;
			popupInner[0].innerHTML = "<p class='popup_text'>日々のSDGsをみんなとシェアしましょう！<br>通期目標と短期目標、それぞれの達成率に応じて背景等が変わる仕組みを用意しました！<br>是非、皆さんのチカラで!!活動で!!このページの楽しさを引き出してください！</p>";
		},
        animateNumber($element, start, end, duration) {
            $({count: start}).animate({count: end}, {
                duration: duration,
                step: function() {
                    $element.text(Math.floor(this.count));
                },
                complete: function() {
                    $element.text(this.count);
                }
            });
        },
        setProgress(short, long) {
            // プログレスバーの設定
            $('.info__bar--progress .bar.yellow').css('width', short + '%');
            $('.info__bar--progress .bar.orange').css('width', long + '%');

            // 短期パーセンテージ吹き出しの設定
            const $numElement = $('.meguru--per--val .num');
            this.animateNumber($numElement, 0, short, 1000); // 1秒間でカウントアップ

            // 短期達成率に対して show クラスを適用するかどうかを判断
            for (let t = 1; t <= 10; t++) {
                if (short >= t * 10) {
                    // クラス名が "level01", "level02", ..., "level10" の形式になっていることを想定
                    const levelClass = 'tankilevel' + t.toString().padStart(2, '0');
                    $('.' + levelClass).addClass('show');
                }
            }

            if (long >= 100){
                $('.tyoki100').addClass('show');
            }

            // 閾値に基づいてレベルのクラスを設定
            const thresholds = [15, 30, 45, 60, 75, 90, 110, 125, 150];
            const levels = ['level02', 'level03', 'level04', 'level05', 'level09', 'level10', 'level11', 'level12', 'level15'];

            thresholds.forEach((threshold, index) => {
                if (long >= threshold) {
                    const levelClass = levels[index];
                    const levelNumber = parseInt(levelClass.replace('level', ''), 10);

                    // levelClass に対応する level までのすべてのクラスに show を付与
                    for (let i = 2; i <= levelNumber; i++) {
                        const currentLevelClass = 'level' + i.toString().padStart(2, '0');
                        $('.' + currentLevelClass).addClass('show');

                        // level9 以降は body への付与を2秒遅延させる
                        if (i >= 9) {
                            setTimeout(() => {
                                $('body').addClass('situation_' + currentLevelClass);
                            }, 2000);
                        } else {
                            $('body').addClass('situation_' + currentLevelClass);
                        }
                    }
                }
            });
        }
    }
}
</script>

<style scoped>
@import "../../../../assets/front_component/scss/style-pc.css";
@import "../../../../assets/front_component/scss/style-sdgs.css";
</style>